<template>
    <div 
        id="app" 
        class="w-full h-full flex flex-col bg-lo-green-lighter no-print overflow-hidden">
        
        <Navigation />
        <router-view />
        <div
            v-if="showMobileWarning"
            class="absolute index-0 w-full h-full text-center p-8 bg-lo-pink"
            style="z-index: 10000;">
            
            <img src="logo.svg" class="h-16 my-12">
            
            <p class="text-white">
                For å bruke denne veilederen må du gå inn på siden fra en datamaskin. 
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                showMobileWarning: false,
            }
        },
        components: {
            'Navigation': () => import('@/components/Navigation.vue'),
        },
        mounted(){
            let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

            if (isMobile) {
                this.showMobileWarning = true;
            }
        }
    }
</script>
