export default {
    data(){
        return {
            backendApiEndpoint: 'https://sammenomendring.no/data/index.php',
            wordpressApiEndpoint: 'https://sammenomendring.no/cms/index.php/wp-json/wp/v2',
            number1: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 444.94" style="padding-left: 3px;"><path fill="currentColor" d="M176.3,418.05h52.08v14.89H5.21V418.05H64.72V60.25L4.46,83.31,0,69.18,149.52,0H176.3Z"/></svg>`,
            number2: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333.26 444.11"><path fill="currentColor" d="M333.26,331q0,46.86-21.95,80t-64,33.11q-42,0-97.82-27.91T69.18,388.31q-43.91,0-54.3,48.35H0q4.46-56.52,41.65-106.38,25.29-33.46,107.13-113.81,21.56-21.57,38.68-51.33a126.25,126.25,0,0,0,17.1-64q0-34.22-20.08-58.77T125,17.86q-25.29,0-46.12,9.67T58,49.48q0,12.27,10,18.22A92.24,92.24,0,0,0,89.64,77q11.52,3.36,21.57,15.26t10,32a46,46,0,0,1-14.5,34.22Q92.23,172.58,67,172.58T25.29,154Q8.91,135.41,8.92,106.38,8.92,61,48,30.5T157.33,0q70.29,0,119.39,30.5t49.1,84.06q0,43.89-20.46,74.39t-52.07,46.12a567.4,567.4,0,0,1-64.72,27.15A618.31,618.31,0,0,0,123.86,289q-31.64,15.25-45,35.34Q96.7,309.48,125,309.46t64.35,9.67q36.06,9.67,56.9,9.66t34.22-2.22q13.4-2.24,25.29-12.65t14.14-29.75h7.43A192.74,192.74,0,0,1,333.26,331Z"/></svg>`,
            number3: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 331.77 448.56"><path fill="currentColor" d="M135.39,0Q305,0,305,102.66q0,40.92-32.35,69.18T180,203.09q67.68.74,109.72,30.49t42,81.08q0,63.24-50.95,98.57T136.88,448.56q-64.72,0-100.8-28.64T0,343.68q0-29.74,16-48.36t42-18.59q26,0,40.54,14.13a46.46,46.46,0,0,1,14.5,34.59q0,20.46-11.16,32.36T77.37,372.69q-35,8.19-35,23.43T63.6,421q21.21,9.68,48.73,9.67,43.13,0,69.92-31.24T209,315q0-53.18-23.43-78.49t-85.18-25.28H88.52V196.38h11.9q47.61,0,68.44-28.63t20.83-65.09q0-36.45-20.83-61T116,17.11q-32,0-48.35,10.42T51.33,49.48q0,11.52,9.3,16.73A76.74,76.74,0,0,0,81.46,74Q93,76.63,102.29,87t9.3,28.26a41.54,41.54,0,0,1-12.65,30.5q-12.66,12.66-35.71,12.65T25.29,142.09Q10.41,125.73,10.42,97.45q0-40.9,33.84-69.18T135.39,0Z" /></svg>`,
            number4: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.8 432.94"><path fill="currentColor" d="M340.69,262.58H357.8V377.14H340.69V326.55h-55v91.5H334v14.89H125.71V418.05h48.35v-91.5H0V311.68L211.26,0h74.39V311.68h55Zm-166.63,49.1V81.82L19.34,311.68Z" /></svg>`,
            number5: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 331.03 466.42"><path fill="currentColor" d="M190.43,46.86q43.14,0,61-4.84t26.41-14.87q8.55-10,16-27.15h7.44a202.6,202.6,0,0,1,6,50.58q0,48.38-23.06,78.85t-67.69,30.51q-81.84,0-148-69.93L52.82,230.6q31.23-29.74,99.68-29.75,90,0,134.26,33.1T331,328.79q0,61.75-49.48,99.68T136.88,466.42q-64.72,0-100.8-28.64T0,361.52q0-29.73,16-48.35t42-18.6q26,0,40.54,14.14a46.47,46.47,0,0,1,14.51,34.59q0,20.46-11.16,32.36T77.36,390.54q-35,8.19-35,23.42t21.2,24.93q21.21,9.67,48.72,9.67,96,0,96-121.26,0-49.83-16.73-77.36t-66.21-27.52q-49.47,0-79.22,37.2l-12.65-3L58.76,29.75Q101.17,46.88,190.43,46.86Z" /></svg>`,
            locked: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.99 11.64"><rect fill="currentColor" y="4.65" width="9.99" height="6.99"/><path path fill="currentColor" d="M3.13,4.65V3.22a1.87,1.87,0,0,1,3.73-.09V4.64H8.21V3.22a3.22,3.22,0,1,0-6.43,0V4.64Z"/></svg>`,
            unlocked: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.21 11.64"><rect fill="currentColor" y="4.65" width="9.99" height="6.99"/><path fill="currentColor" d="M8.13,4.65V3.22a1.86,1.86,0,1,1,3.72-.09V4.64h1.36V3.22a3.22,3.22,0,0,0-6.44,0V4.64Z"/></svg>`,
            flag: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.66 22.77"><path fill="currentColor" d="M9.66,0H8.16V19.17h1.5V12l13-6Z"/><path fill="currentColor" d="M16.89,17.48a6.34,6.34,0,0,0-2-1,15.55,15.55,0,0,0-2.53-.61v1.57a12.78,12.78,0,0,1,2,.49,5.13,5.13,0,0,1,1.51.77.8.8,0,0,1,.36.5c0,.14-.13.32-.36.51a5.13,5.13,0,0,1-1.51.77A14.76,14.76,0,0,1,12,21a22.33,22.33,0,0,1-6.11,0,14.76,14.76,0,0,1-2.45-.57,5.13,5.13,0,0,1-1.51-.77.8.8,0,0,1-.35-.51.82.82,0,0,1,.35-.5A5.13,5.13,0,0,1,3.4,17.9a13.32,13.32,0,0,1,2-.49V15.84a15.55,15.55,0,0,0-2.53.61,6.45,6.45,0,0,0-2,1,2,2,0,0,0-.59,2.79,2,2,0,0,0,.59.6,6.8,6.8,0,0,0,2,1,15.92,15.92,0,0,0,2.73.64,24,24,0,0,0,6.56,0,15.81,15.81,0,0,0,2.72-.64,6.55,6.55,0,0,0,2-1,2,2,0,0,0,.6-2.79,2.14,2.14,0,0,0-.6-.6Z"/></svg>`,
            star: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.66 24.4"><path fill="currentColor" d="M12.83,0l4,8,8.86,1.29-6.41,6.25,1.51,8.83-7.93-4.16L4.9,24.4l1.52-8.83L0,9.32,8.86,8Z"/></svg>`,
        }
    },
    methods: {
        openLink( link ){
            this.$router.push( link, () => {
                this.$router.go();
                // console.log('done', this.$router);
            })
        },
        async fetchPages(){
            try {
                const response = await this.$axios.get( this.wordpressApiEndpoint + '/pages?per_page=100&orderby=menu_order&order=asc');
                return response.data;
            }
            catch (error) {
                console.error(error);
            }
        },
        async searchPages(searchString){
            try {
                const response = await this.$axios.get( this.wordpressApiEndpoint + '/search/?search='+searchString);
                return response.data;
            }
            catch (error) {
                console.error(error);
            }
        },
    }
}
