import Vue from 'vue'
import Router from 'vue-router'
import Store from './store'

Vue.use(Router)
Vue.use(Store)

import Axios from 'axios'

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'hjem',
            component: () => import('./views/Hjem.vue'),
        },
        {
            path: '/veileder',
            name: 'veileder',
            component: () => import('./views/Veileder.vue'),
        },
        {
            path: '/artikkel/:id',
            name: 'artikkel',
            component: () => import('./views/Artikkel.vue'),
        },
    ]
})

router.beforeEach(async (to, from, next) => {
    if (
        to.query &&
        to.query.id){
        
        const id = to.query.id;
        
        if ( !Store.state.form ) {
            const data = {
                id,
                task: 'getSession',
            };
            
            const response = await Axios.post( 'https://sammenomendring.no/data/index.php', data);
            Store.state.form = response.data;
            Store.state.id = id;
        }
    }
    
    next()

})

export default router;
