import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pages: null,
        form: null,
        id: null,
    },
    
    getters: {
        // getReport: state => {
        //     return state.report;
        // },
        // getAccountBrandKit: state => {
        //     return state.accountBrandKit;
        // }
    },
    
    mutations: {
        // setAccountGeneral(state, account) {
        //     // reset account
        //     Object.keys(state.account).forEach( key => {
        //         state.account[key] = null;
        //     });
        // 
        //     // merge account properties
        //     state.account = Object.assign(state.account, account);
        //     // console.log('state.account after', state.account.subscription);
        // },
    },
    actions: {
        
    }
})
