
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VuePlyr from 'vue-plyr'

import Axios from 'axios'
Vue.prototype.$axios = Axios;

Vue.config.productionTip = false

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)


// Vue.use(VuePlyr, {
//     plyr: {
//         fullscreen: { enabled: true }
//     },
//     emit: ['ended']
// })

import VueEvents from 'vue-events';
Vue.use(VueEvents);

import VueConfetti from 'vue-confetti'
Vue.use(VueConfetti)

// Global Mixin
import GlobalMixins from '@/mixins/GlobalMixins'
Vue.mixin(GlobalMixins);

// Tailwind
import '@/assets/css/tailwind.css'
import '@/assets/css/styles.scss'

// VUE Init
new Vue({
    store,
    router,
    render: h => h(App),
    async mounted() {
        // document.title = '';
    }
}).$mount('#app')
